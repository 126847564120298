.aboutus-wrapper {
  display: flex;
  flex-direction: column !important;
  padding: 5%;
}

.aboutus-inner-wrapper {
  border-top: gray 1px solid;
  padding-top: 20px;
}

.aboutus-title {
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  padding: 10px 0;
}

.aboutus-first-inner-wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.aboutus-inner-inner-wrapper > * {
  margin: 20px 0px !important;
}

.aboutus-image {
  width: 100%;
}

@media (min-width: 778px) {
  .aboutus-wrapper {
    margin: 1% 20%;
    padding: 0px;
  }

  .aboutus-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    padding: 10px 0 20px 0;
  }

  .aboutus-info-wrapper {
    display: flex;
    flex-direction: row !important;
    flex: 1;
  }

  .aboutus-first-inner-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .aboutus-first-inner-wrapper > * {
    width: 48%;
  }

  .first-aboutus-image {
    max-height: 350px;
  }

  .second-aboutus-image {
    max-height: 450px;
  }

  .first-aboutus-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}

@media (min-width: 778px) and (max-width: 1120px) {
  .aboutus-wrapper {
    margin: 1% 10%;
    padding: 0px;
  }
}
